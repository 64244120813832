.phoneIcon {
    height: 20px;
}

.bg-rug-10 {
    background: red;
}

.bg-rug-20 {
    background: green;
}

.bg-rug-30 {
    background: blue;
}

.bg-rug-40 {
    background: violet;
}

.primary-color {
    color: #ebca45
}

.primary-background-color {
    background-color: #ebca45
}

.secondary-color {
    color: #100d01
}

.secondary-background-color {
    background-color: #100d01
}

.product-submit-btn {
    border-radius: 8px;
    background-color: #100d01;
    color: #fff;
}