.rectangle {
    height: 0.3em;
    width: 2em;
}

.homeBg {
    background-image: url('../../imgs/bsport-banner.jpg');
}

.product-img {
    background-image: url('../../imgs/product-01.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.wrapper {
    position: relative;
}